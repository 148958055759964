<template>
  <div class="v_ap_receiving_gift_card_m_detail_card bbox d-flex flex-column align-center"
    :class="{mobile}"
  >
    <div class="detail_group bbox d-flex flex-column align-center bbox">
      <div class="card_group bbox d-flex flex-column align-center ">
        <img src="~@/assets/images/personal_center/gift_card/receiving/success.png" alt="" class="success">
        <div class="title">恭喜您</div>
        <div class="title">获得 {{pcarduser.card.vipdays}}天 妙行会员资格</div>
        <div class="info_group d-flex flex-column align-center">
          <div class="phone_group d-flex align-center justify-center">
            <div class="text">请使用 </div>
            <div class="phone">{{userinfo.phone}}</div>
            <div class="text"> 手机号码登录查阅</div>
          </div>
          <div class="time_group">
            VIP有效期至：{{$moment(pcarduser.vipEndDate).format('YYYY年MM月DD日')}}
          </div>
        </div>
        <div class="button btn_dark"
          v-text="btn_text"
          @click.stop="goToPmcs()"
        >前往妙行网页版</div>
      </div>
    </div>
    <about-us
      v-if="!mobile"
      class="about_us"
      :mobile="mobile"
    ></about-us>
  </div>
</template>

<script>
import AboutUs from './AboutUs.vue'
import app from '@/api/modules/app'
export default {
  components: {
    AboutUs,
  },
  props: {
    mobile:{
      type: Boolean,
      default: false,
    },
    userinfo: {
      type: Object,
      default: undefined,
    },
    pcarduser: {
      type: Object,
      default: undefined,
    },
    page: {
      type: Object,
      default: undefined,
    },
  },
  computed: {
    btn_text(){
      const {mobile} = this
      if(mobile){
        return '下载/打开妙行APP'
      }
      return '前往妙行网页版'
    }
  },
  methods: {
    goToPmcs(){
      const isWechat = app.isWechat()
      const isIos = app.isIos()
      const {mobile, page} = this
      const {downloadAppAndroid,downloadAppIOS} = page
      if(mobile){
        if(isWechat){
          window.location.href='https://a.app.qq.com/o/simple.jsp?pkgname=com.ourutec.pmcs'
          return
        }
        if(!isWechat || isIos){
          if(isIos){
            app.downloadIos({url: downloadAppIOS})
            return
          }
          app.downloadAndroid({url: downloadAppAndroid})
          return
        }
      }
      window.open('https://pmcs.yourutec.com/', '_blank')
    }
  }
}
</script>

<style lang="scss" scoped>
.v_ap_receiving_gift_card_m_detail_card{
  width: 100%;
  height: 100%;
  padding-top: 60px;
  padding-bottom: 50px;
  overflow: auto;
  .detail_group{
    width: 620px;
    background: #FFFFFF;
    border-radius: 10px;
    padding: 50px;
  }
  .card_component{
    margin-top: 20px;
  }
  .success{
    margin-top: 70px;
    margin-bottom: 40px;
  }
  .title{
    font-size: 24px;
    line-height: 33px;
  }
  .info_group{
    padding: 30px 60px;
    border-radius: 4px;
    background-color: #f8f8f8;
    font-size: 16px;
    color: #666;
    margin-top: 80px;
    .phone_group{
      padding-bottom: 16px;
      .phone{
        color: $main_blue;
        padding: 0 6px;
      }
    }
  }
  .button{
    width: 330px;
    line-height: 50px;
    border-radius: 4px;
    font-size: 16px;
    text-align: center;
    margin-top: 60px;
  }
  &.mobile{
    background-color: #fff;
    .detail_group{
      width: 100%;
      padding: 0.5rem;
    }
    .title{
      font-size: 20px;
      line-height: 26px;
    }
    .info_group{
      padding: 0.3rem 0.6rem;
      font-size: 14px;
    }
    .button{
      width: 5rem;
    }
  }
}
</style>
