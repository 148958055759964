var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "v_ap_receiving_gift_card_m_error bbox d-flex flex-column align-center justify-center",
      class: { mobile: _vm.mobile },
    },
    [
      _c(
        "div",
        {
          staticClass:
            "main_group d-flex flex-column justify-center align-center bbox",
        },
        [
          !_vm.mobile
            ? _c("img", {
                staticClass: "logo",
                attrs: { src: require("@/assets/logo.png"), alt: "" },
              })
            : _vm._e(),
          _c(
            "div",
            {
              staticClass:
                "info_group d-flex flex-column justify-center align-center bbox",
            },
            [
              _c("img", {
                staticClass: "error",
                attrs: {
                  src: require("@/assets/images/personal_center/gift_card/receiving/error.png"),
                  alt: "",
                },
              }),
              _c("div", { staticClass: "hint" }, [_vm._v(_vm._s(_vm.hint))]),
              _c(
                "div",
                {
                  staticClass: "button btn_dark",
                  domProps: { textContent: _vm._s(_vm.btn_text) },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.goToPmcs()
                    },
                  },
                },
                [_vm._v("前往妙行网页版")]
              ),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }