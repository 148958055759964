var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "v_ap_receiving_gift_card_index" },
    [
      _vm.page.type === 1 && _vm.pcarduser
        ? _c("detail-card", {
            attrs: {
              mobile: _vm.mobile,
              userinfo: _vm.userinfo,
              pcarduser: _vm.pcarduser,
              page: _vm.page,
              "term-of-validity": _vm.termOfValidity,
              "route-get-time": _vm.routeGetTime,
            },
          })
        : _vm._e(),
      _vm.page.type === 3
        ? _c("error-component", {
            attrs: {
              mobile: _vm.mobile,
              userinfo: _vm.userinfo,
              pcarduser: _vm.pcarduser,
              page: _vm.page,
            },
          })
        : _vm._e(),
      _vm.page.type === 2
        ? _c("success-component", {
            attrs: {
              mobile: _vm.mobile,
              userinfo: _vm.userinfo,
              pcarduser: _vm.pcarduser,
              page: _vm.page,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }