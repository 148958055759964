<template>
  <div class="v_ap_receiving_gift_card_index">
    <detail-card
      :mobile="mobile"
      :userinfo="userinfo"
      :pcarduser="pcarduser"
      :page="page"
      :term-of-validity="termOfValidity"
      :route-get-time="routeGetTime"
      v-if="page.type === 1 && pcarduser"
    ></detail-card>
    <error-component
      :mobile="mobile"
      :userinfo="userinfo"
      :pcarduser="pcarduser"
      :page="page"
      v-if="page.type === 3"
    ></error-component>
    <success-component
      :mobile="mobile"
      :userinfo="userinfo"
      :pcarduser="pcarduser"
      :page="page"
      v-if="page.type === 2"
    ></success-component>
  </div>
</template>

<script>
import DetailCard from './Modules/DetailCard.vue'
import self_adaption from '@/utils/self_adaption'
import rem from '@/utils/self_adaption/rem'
import global from '@/api/global'
import {PCardUser} from '@/types'
import ErrorComponent from './Modules/Error.vue'
import SuccessComponent from './Modules/Success.vue'
import app from '@/api/modules/app'
export default {
  components: {
    DetailCard,
    ErrorComponent,
    SuccessComponent,
  },
  mounted(){
    this.resizeHandler()
    window.addEventListener('resize', ()=>{
      this.resizeHandler()
    })

    this.init()
  },
  beforeDestroy(){
    rem.remove()
    window.removeEventListener('resize', this.resizeHandler)
  },
  data(){
    return {
      routeGetTime: '',
      orcode: '',
      mobile: false,
      pcarduser: undefined,
      userinfo: {
        phone: '',
        phonecode: '',
        userPassword: '',
        userName: '',
        countdown: 0,
        selecte_aggrement: false,
        timer: undefined,
        newuser: false,
      },
      // 页面信息
      page: {
        type: 1,
        error: '',
        downloadAppAndroid: '',
        downloadAppIOS: '',
      },
    }
  },
  methods: {
    resizeHandler(){
      const {mobile} = self_adaption.set({
        width: 750,
        font_size: 14,
      })
      this.mobile = mobile
    },
    async init(){
      const {$route,userinfo} = this
      const {query} = $route
      const {orcode, getTime} = query
      this.orcode = orcode
      this.routeGetTime = getTime
      this.getCard()
      this.getAppUrl()
    },
    async getCard(){
      const { orcode, routeGetTime: getTime } = this
      const res = await global.doPost({
        url: '/card/queryUserCardByOrcode',
        data: {
          orcode,
          getTime
        },
        openNotice: false,
      })
      if(res.message === 'success'){
        const {contents} = res
        if(!contents){
          this.$set(this, 'pcarduser', undefined)
          return
        }
        this.$set(this, 'pcarduser', new PCardUser(Object.assign(
          {},
          contents.PCardUser,
          {
            orcode
          }
        )))
        this.termOfValidity = contents.getTime
      }else{
        this.page.type = 3
        this.page.error = res.describe
      }
    },
    async getAppUrl(){
      const res = await app.getAppUrl()
      if(res){
        const {downloadAppAndroid,downloadAppIOS} = res
        this.page.downloadAppAndroid = downloadAppAndroid
        this.page.downloadAppIOS = downloadAppIOS
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.v_ap_receiving_gift_card_index{
  @include scrollbar;
  overflow: auto;
  background-color: #F5F6F7;
  height: 100%;
  width: 100%;
}
</style>
