var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "v_ap_receiving_gift_card_m_detail_card bbox d-flex flex-column align-center",
      class: { mobile: _vm.mobile },
    },
    [
      _c(
        "div",
        {
          staticClass: "detail_group bbox d-flex flex-column align-center bbox",
        },
        [
          _c(
            "div",
            { staticClass: "card_group bbox d-flex flex-column align-center" },
            [
              _c("img", {
                staticClass: "success",
                attrs: {
                  src: require("@/assets/images/personal_center/gift_card/receiving/success.png"),
                  alt: "",
                },
              }),
              _c("div", { staticClass: "title" }, [_vm._v("恭喜您")]),
              _c("div", { staticClass: "title" }, [
                _vm._v(
                  "获得 " +
                    _vm._s(_vm.pcarduser.card.vipdays) +
                    "天 妙行会员资格"
                ),
              ]),
              _c(
                "div",
                { staticClass: "info_group d-flex flex-column align-center" },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "phone_group d-flex align-center justify-center",
                    },
                    [
                      _c("div", { staticClass: "text" }, [_vm._v("请使用 ")]),
                      _c("div", { staticClass: "phone" }, [
                        _vm._v(_vm._s(_vm.userinfo.phone)),
                      ]),
                      _c("div", { staticClass: "text" }, [
                        _vm._v(" 手机号码登录查阅"),
                      ]),
                    ]
                  ),
                  _c("div", { staticClass: "time_group" }, [
                    _vm._v(
                      "\n          VIP有效期至：" +
                        _vm._s(
                          _vm
                            .$moment(_vm.pcarduser.vipEndDate)
                            .format("YYYY年MM月DD日")
                        ) +
                        "\n        "
                    ),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "button btn_dark",
                  domProps: { textContent: _vm._s(_vm.btn_text) },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.goToPmcs()
                    },
                  },
                },
                [_vm._v("前往妙行网页版")]
              ),
            ]
          ),
        ]
      ),
      !_vm.mobile
        ? _c("about-us", {
            staticClass: "about_us",
            attrs: { mobile: _vm.mobile },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }