<template>
  <div
    v-if="pcarduser"
    class="v_ap_receiving_gift_card_m_detail_card bbox d-flex flex-column align-center"
    :class="{mobile}"
  >
    <div class="detail_group bbox d-flex flex-column align-center bbox">
      <div class="card_group bbox d-flex flex-column align-center ">
        <img src="~@/assets/logo.png" alt="" class="logo"
          v-show="!mobile"
        >
        <div class="text_group"
          v-if="sendword_list && sendword_list.length"
        >
          <template
            v-for="(item, index) in sendword_list"
          >
            <div class="text"
              :key="index"
            >{{item}}</div>
          </template>
        </div>
        <card-component
          v-if="pcarduser"
          class="card_component"
          :mobile="mobile"
          :width="card_width"
          :height="card_height"
          :pcard="pcarduser.card"
          :pcarduser="pcarduser"
          :show_indate="!mobile"
          :show_cardno="true"
          :term-of-validity="termOfValidity"
        ></card-component>
        <div
          v-if="mobile"
          class="indate"
        >
          请在 {{ $moment(termOfValidity).format('yyyy-MM-DD') }} 之前领取
        </div>
        <div class="hint_group">
          <template
            v-for="(item, index) in hint_list.split('\n')"
          >
            <div class="hint"
              :key="index"
            >{{item}}</div>
          </template>
        </div>
      </div>
      <div class="button btn_dark"
        @click.stop="openGiftCardReceiving()"
      >立即领取</div>
    </div>
    <about-us
      class="about_us"
      :mobile="mobile"
    ></about-us>
  </div>
</template>

<script>
import CardComponent from '@/views/PersonalCenter/GiftCard/Modules/Card.vue'
import AboutUs from './AboutUs.vue'
import moment from 'moment'
export default {
  components: {
    CardComponent,
    AboutUs,
  },
  props: {
    mobile:{
      type: Boolean,
      default: false,
    },
    userinfo: {
      type: Object,
      default: undefined,
    },
    pcarduser: {
      type: Object,
      default: undefined,
    },
    page: {
      type: Object,
      default: undefined,
    },
    termOfValidity: {
      type: String,
      default: ''
    },
    routeGetTime: {
      type: String,
      default: ''
    }
  },
  computed: {
    card_width(){
      const {mobile} = this
      if(mobile){
        return '5.9rem'
      }
      return '422px'
    },
    card_height(){
      const {mobile} = this
      if(mobile){
        return '3.6rem'
      }
      return '244px'
    },
    sendword_list(){
      const {pcarduser} = this
      if(!pcarduser){
        return false
      }
      const {sendword} = pcarduser
      return sendword.split('\n')
    }
  },
  data(){
    return {
      text_list: [
        '哈喽！',
        '我正在使用妙行完成实践教学任务',
        '送你一张会员体验卡',
        '跟我一起来体验【项目思维】下的高效沟通，和【边做边学】流程管理方法',
      ],
      hint_list: '温馨提示：\n1、每张礼品卡仅限一人领取使用。\n2、礼品卡是妙行推出的具有不同权益的虚拟卡，包含的权益以卡面信息为准。',
      orcode: undefined,
    }
  },
  methods: {
    openGiftCardReceiving(){
      const { pcarduser, userinfo, mobile, page, termOfValidity, routeGetTime } = this
      if (!termOfValidity) {
        this.$notice({
          desc: '不在有效期内'
        })
        return
      }
      if (moment(termOfValidity).format('yyyy-MM-DD') === 'Invalid date') {
        this.$notice({
          desc: '不在有效期内'
        })
        return
      }
      this.$openGiftCardReceiving({
        userinfo,
        pcarduser,
        mobile,
        page,
        routeGetTime,
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.v_ap_receiving_gift_card_m_detail_card{
  width: 100%;
  height: 100%;
  padding-top: 60px;
  padding-bottom: 50px;
  .detail_group{
    width: 620px;
    background: #FFFFFF;
    border-radius: 10px;
    padding: 50px;
  }
  .logo{
    width: 50px;
    height: 50px;
  }
  .card_group{

  }
  .text_group{
    width: 100%;
    margin-top: 10px;
    font-size: 15px;
    color: #333;
    line-height: 30px;
  }
  .card_component{
    margin-top: 20px;
    height: 3.6rem;
  }
  .hint_group{
    width: 100%;
    margin-top: 16px;
    font-size: 14px;
    color: #82929C;
    line-height: 20px;
  }
  .button{
    width: 330px;
    font-size: 16px;
    color: #FFFFFF;
    border-radius: 4px;
    text-align: center;
    line-height: 50px;
    margin-top: 50px;
  }
  &.mobile{
    padding-bottom: 0.5rem;
    padding-top: 0;
    .detail_group{
      width: 100%;
      padding: 0.5rem;
      background-color: #E5EDF6;
    }
    .card_group{
      width: 100%;
      padding: 0.3rem;
      border-radius: 0.1rem;
      background-color: #fff;
    }
    .indate{
      font-size: 13px;
      color: #666;
      width: 100%;
      margin-top: 10px;
    }
    .text_group{
      font-size: 13px;
      line-height: 30px;
    }
    .button{
      width: 5.7rem;
      margin-top: 0.5rem;
      line-height: 40px;
      font-size: 14px;
    }
  }

}
</style>
