<template>
  <div class="v_ap_receiving_gift_card_m_error bbox d-flex flex-column align-center justify-center"
    :class="{mobile}"
  >
    <div class="main_group d-flex flex-column justify-center align-center bbox">
      <img src="~@/assets/logo.png" alt="" class="logo"
        v-if="!mobile"
      >
      <div class="info_group d-flex flex-column justify-center align-center bbox">
        <img src="@/assets/images/personal_center/gift_card/receiving/error.png" alt="" class="error">
        <div class="hint">{{hint}}</div>
        <div class="button btn_dark"
          v-text="btn_text"
          @click.stop="goToPmcs()"
        >前往妙行网页版</div>
      </div>
    </div>
  </div>
</template>

<script>
import app from '@/api/modules/app'
export default {
  props: {
    page: {
      type: Object,
      default: undefined,
    },
    mobile: {
      type: Boolean,
      default: false,
    }
  },
  computed:{
    hint(){
      const {page} = this
      if(!page){
        return '礼品卡不存在'
      }
      const {error} = page
      return error || '礼品卡不存在'
    },
    btn_text(){
      const {mobile} = this
      if(mobile){
        return '下载/打开妙行APP'
      }
      return '前往妙行网页版'
    }
  },
  methods: {
    goToPmcs(){
      const isWechat = app.isWechat()
      const isIos = app.isIos()
      const {mobile, page} = this
      const {downloadAppAndroid,downloadAppIOS} = page
      if(mobile){
        if(isWechat){
          window.location.href='https://a.app.qq.com/o/simple.jsp?pkgname=com.ourutec.pmcs'
          return
        }
        if(!isWechat || isIos){
          if(isIos){
            app.downloadIos({url: downloadAppIOS})
            return
          }
          app.downloadAndroid({url: downloadAppAndroid})

          return
        }
      }
      window.open('https://pmcs.yourutec.com/', '_blank')
    }
  }
}
</script>


<style lang="scss" scoped>
.v_ap_receiving_gift_card_m_error{
  width: 100%;
  height: 100%;
  .main_group{
    width: 600px;
    height: 80%;
    background: #FFFFFF;
    border-radius: 20px;
    position: relative;
    .logo{
      position: absolute;
      width: 50px;
      height: 50px;
      top: 50px;
      left: 60px;
    }
    .hint{
      font-size: 20px;
      color: #333333;
      line-height: 26px;
      padding-top: 30px;
      padding-bottom: 100px;
    }
    .button{
      width: 330px;
      line-height: 50px;
      border-radius: 4px;
      font-size: 16px;
      text-align: center;
    }
  }
  &.mobile{
    background-color: #fff;
    .main_group{
      width: 100%;
    }
    .error{
      width: 5.4rem;
    }
    .button{
      width: 5rem;
    }
  }
}
</style>
